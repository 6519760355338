import React, {memo, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {CSSTransition} from "react-transition-group";
import s from "./NotificationMenuHeader.module.css";
import {
    INotification,
    INotificationPrivateChat,
    INotificationStart,
    INumbOfNotifications
} from "../../../../store/reducers/notifications/types/notificationsTypes";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks/redux";
import {
    getProfileGroupId
} from "../../../../store/reducers/profile/selectors/getProfile/getProfile";
import {profileAction} from "../../../../store/reducers/profile/profileSlice";
import {UserGroup} from "../../../../store/reducers/profile/types/profileTypes";
import {RoutePath} from "../../../../router/routerConfig/routerConfig";
import {notificationsAction} from "../../../../store/reducers/notifications/notificationsSlice";
import {useNavigate} from "react-router-dom";
import {NotificationItemLink} from "./NotificationItemLink";
import {ProfileModal} from "../../../modal/profile/ProfileModal";
import {useProfileModal} from "../../../../hooks/useProfileModal";
import {chatsAction} from "../../../../store/reducers/chats/chatsSlice";
import {roomsAction} from "../../../../store/reducers/rooms/roomsSlice";
import {
    markReadeNotification
} from "../../../../store/reducers/notifications/services/markReadeNotification/markReadeNotification";
import {
    getNotificationsIsNotificationIsLoading
} from "../../../../store/reducers/notifications/selectors/getNotifications";
import {
    navigateRoomThunk
} from "../../../../store/reducers/rooms/services/navigateRoom/navigateRoomThunk";
import {
    navigateChatThunk
} from "../../../../store/reducers/chats/services/navigateChat/navigateChatThunk";
import {classNames} from "../../../../utils/helpers/classNames/classNames";
import {snackbarAction} from "../../../../store/reducers/errors/SnackbarSlice";
import {
    fetchProfileInfoByIdToNavigate
} from "../../../../store/reducers/profileInfo/services/fetchProfileInfoByIdToNavigate/fetchProfileInfoByIdToNavigate";

type ProfileMenuHeaderPropsType = {
    isShow: boolean;
    notificationData?: INotification[]
    notificationStart?: INotificationStart
    notificationPrivateChat?: INotificationPrivateChat
    numberOfNewNotifications: INumbOfNotifications
    closeNotification?: () => void
};

export const NotificationMenuHeader = memo((props: ProfileMenuHeaderPropsType) => {
    const {
        isShow,
        notificationData,
        numberOfNewNotifications,
        closeNotification,
        notificationStart,
        notificationPrivateChat
    } = props
    const {t} = useTranslation(["notification"]);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const profileGroupId = useAppSelector(getProfileGroupId)

    //profileModal
    const {
        profileModalId,
        isShowProfile,
        onShowProfileModal,
        onCloseProfile
    } = useProfileModal()

    const onShowProfileModalHandler = useCallback(async (idUser: number) => {
        // const url = window.location.pathname;
        // const hasUrl = url.includes(`specialist-profile/${idUser}`) || url.includes(`profile-user/${idUser}`);
        // if (!hasUrl) {
        dispatch(fetchProfileInfoByIdToNavigate({
            id: idUser,
            navigate
        }))
        closeNotification?.()
    }, [closeNotification, dispatch, navigate])

    const myGroup = useAppSelector(getProfileGroupId)

    const notificationsIsLoading = useAppSelector(getNotificationsIsNotificationIsLoading)

    const shouldRenderNotifications = notificationData
        && notificationData.length > 0
        || notificationStart
        || numberOfNewNotifications.newFriends > 0
        || numberOfNewNotifications.newRequestInRooms > 0
        || numberOfNewNotifications.newRequestInChats > 0
        || notificationPrivateChat

    const navigateStartSession = useCallback(async () => {
        closeNotification?.()
        // if(notificationStart?.schedule?.id) {
        //     window.open(`${window.location.origin}/${RoutePath.calls}${notificationStart?.schedule?.id}`)
        // }
        if (notificationStart?.schedule?.id) {
            window.open(`${window.location.origin}${RoutePath.calls}${notificationStart?.schedule?.id}`)
        }
    }, [closeNotification, notificationStart?.schedule?.id])

    const navigateFriendRequests = useCallback(async () => {
        await dispatch(profileAction.setStatusFilterFriends('requests'))
        if (myGroup === UserGroup.SPEC || myGroup === UserGroup.SPEC_PREMIUM) {
            navigate(RoutePath.specialist_friends)
        } else if (myGroup === UserGroup.USER_PREMIUM || myGroup === UserGroup.USER) {
            navigate(RoutePath.profile_friends)
        }
        closeNotification?.()
        dispatch(notificationsAction.clearAddNewFriends())
    }, [closeNotification, dispatch, myGroup, navigate])

    const navigateChatsRequests = useCallback(async () => {
        await dispatch(chatsAction.setStatusFilter('requests'))
        if (myGroup === UserGroup.SPEC || myGroup === UserGroup.SPEC_PREMIUM) {
            navigate(RoutePath.specialist_chats)
        } else if (myGroup === UserGroup.USER_PREMIUM || myGroup === UserGroup.USER) {
            navigate(RoutePath.profile_chats)
        }
        closeNotification?.()
        dispatch(notificationsAction.clearRequestsInChats())
    }, [closeNotification, dispatch, myGroup, navigate])

    const navigateRoomsRequests = useCallback(async () => {
        await dispatch(roomsAction.setStatusFilter('requests'))
        if (myGroup === UserGroup.SPEC || myGroup === UserGroup.SPEC_PREMIUM) {
            navigate(RoutePath.specialist_rooms)
        } else if (myGroup === UserGroup.USER_PREMIUM || myGroup === UserGroup.USER) {
            navigate(RoutePath.profile_rooms)
        }
        closeNotification?.()
        dispatch(notificationsAction.clearRequestsInRooms())
    }, [closeNotification, dispatch, myGroup, navigate])

    const navigateRoom = useCallback((idRoom: number) => {
        dispatch(navigateRoomThunk({
            idRoom,
            navigate
        }))
    }, [dispatch, navigate])

    const navigateChat = useCallback((idChat: number) => {
        dispatch(navigateChatThunk({
            navigate,
            idChat
        }))
    }, [dispatch, navigate])

    const navigateMyCabinet = useCallback(() => {
        dispatch(profileAction.setStatusFilterCabinet('events'))
        if (myGroup === UserGroup.USER_PREMIUM || myGroup === UserGroup.USER) {
            navigate(RoutePath.profile_cabinet)
        }
        if (myGroup === UserGroup.SPEC_PREMIUM || myGroup === UserGroup.SPEC) {
            navigate(RoutePath.specialist_cabinet)
        }
        closeNotification?.()
    }, [closeNotification, dispatch, myGroup, navigate])

    const navigateMyChats = useCallback(() => {
        if (myGroup === UserGroup.USER_PREMIUM || myGroup === UserGroup.USER) {
            navigate(RoutePath.profile_messages)
        } else if (myGroup === UserGroup.SPEC_PREMIUM || myGroup === UserGroup.SPEC) {
            navigate(RoutePath.specialist_messages)
        }
        dispatch(notificationsAction.clearNotificationPersonalChat())
        dispatch(snackbarAction.clearNotificationPrivateMessage())
        closeNotification?.()
    }, [closeNotification, dispatch, myGroup, navigate])

    const clearNotification = useCallback((idNotification: number) => {
        dispatch(markReadeNotification({id: idNotification}))
    }, [dispatch])

    const renderNewNotificationPrivateChat = notificationPrivateChat ?
        <div
            className={classNames(s.notiWrp__item, {}, [])}
            onClick={navigateMyChats}
        >
            <p className={s.sessionStartBox}>
                {t('notification:private-chat')}
            </p>
        </div>
        : null

    const renderStartConference = notificationStart ?
        <div
            className={classNames(s.notiWrp__item, {}, [])}
            onClick={navigateStartSession}
        >
            <p className={s.sessionStartBox}>
                {t('notification:calendar')}
                {' '}
                <span className={s.conference}>
                    {notificationStart.schedule ? notificationStart.schedule?.name : ''}
                </span>
                {' '}
                {t('notification:calendar-start')}
                {' '}
                {
                    notificationStart.schedule?.room?.name
                        ? <>

                            <span className={s.text}>
                                {t('notification:calendar-room')}
                            </span>
                            {' '}
                            <span className={s.conference}>
                                {notificationStart.schedule?.room?.name}
                            </span>
                        </>
                        : null
                }
            </p>
            <span className={s.countStart}>

            </span>
        </div>
        : null

    const renderAddNewFriends = numberOfNewNotifications.newFriends > 0 ? (
        <div
            className={s.notiWrp__item}
            onClick={navigateFriendRequests}
        >
            <p>
                {t('notification:title-newFriends')}
            </p>
            <span className={s.count}>
                {numberOfNewNotifications.newFriends > 9 ? '9+' : numberOfNewNotifications.newFriends}
            </span>
        </div>
    )
        : null

    const renderAddNewRequestsInChats = numberOfNewNotifications.newRequestInChats > 0 ? (
        <div
            className={s.notiWrp__item}
            onClick={navigateChatsRequests}
        >
            <p>
                {t('notification:title-new-requests-chat')}
            </p>
            <span className={s.count}>
                {numberOfNewNotifications.newRequestInChats > 9 ? '9+' : numberOfNewNotifications.newRequestInChats}
            </span>
        </div>
    )
        : null

    const renderAddNewRequestsInRooms = numberOfNewNotifications.newRequestInRooms > 0 ? (
        <div
            className={s.notiWrp__item}
            onClick={navigateRoomsRequests}
        >
            <p>
                {t('notification:title-new-requests-room')}
            </p>
            <span className={s.count}>
                {numberOfNewNotifications.newRequestInRooms > 9 ? '9+' : numberOfNewNotifications.newRequestInRooms}
            </span>
        </div>
    )
        : null

    return (
        <>
            <CSSTransition
                timeout={500}
                in={isShow}
                classNames={"headerNotification"}
                mountOnEnter
                unmountOnExit
            >
                <div className={s.header__notificationBox}>
                    <div className={`notiWrp`}>

                        {!shouldRenderNotifications &&
                            <div
                                className={`notiWrp__title`}>
                                {t("notification:no-notification")}
                            </div>}

                        {shouldRenderNotifications && <>
                            <div className={`notiWrp__title`}>{t("notification:title")}</div>

                            {renderStartConference}
                            {renderAddNewFriends}
                            {renderAddNewRequestsInChats}
                            {renderAddNewRequestsInRooms}
                            {renderNewNotificationPrivateChat}

                            {notificationData && notificationData.map(el => (
                                <NotificationItemLink
                                    key={el.id}
                                    notification={el}
                                    clearNotification={clearNotification}
                                    notificationIsLoading={notificationsIsLoading}
                                    showProfile={onShowProfileModalHandler}
                                    navigateRoom={navigateRoom}
                                    navigateChat={navigateChat}
                                    navigateMyCabinet={navigateMyCabinet}
                                    closeNotification={closeNotification}
                                />
                            ))}

                        </>}
                    </div>
                </div>
            </CSSTransition>

            {profileModalId && <ProfileModal
                isOpen={isShowProfile}
                id={profileModalId}
                onClose={onCloseProfile}
            />}
        </>
    );
});
