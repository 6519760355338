import {useEffect, useMemo, useState} from "react";

import {HeaderLogo} from "../header/headerLogo/HeaderLogo";
import {HeaderItem} from "../header/headerItem/HeaderItem";
import s from "./Navbar.module.css";
import {Container} from "../../common/contaiiner/Container";
import {useAppSelector} from "../../store/hooks/redux";
import {SwitchLang} from "./switchLang/SwitchLang";
import {useTranslation} from "react-i18next";
import {ITitlePath} from "../../models/ITitlePath";
import {LoginModal} from "../modal/loginModal/LoginModal";
import {RoutePath} from "../../router/routerConfig/routerConfig";
import {classNames, Mods} from "../../utils/helpers/classNames/classNames";
import {getNavbarShow} from "../../store/reducers/navbar/getShowNavbar/getNavbarShow";
import {
    getShowNavbarDesktop
} from "../../store/reducers/navbar/getShowNavbarDesktop/getShowNavbarDesktop";
import {
    getAuthIsAuthentication,
    getAuthIsNavbarLoading,
    getAuthIsShowModal,
} from "../../store/reducers/auth/selectors/getAuth/getAuth";
import {CustomButton} from "../../ui/Button/CustomButton";
import {HeaderProfile} from "../header/headerProfile/HeaderProfile";
import {HeaderLogout} from "../header/headerLogout/HeaderLogout";
import {NavbarLoader} from "../../ui/navbarLoader/NavbarLoader";
import {
    getProfileIsLoading,
    getProfileIsLoadingProfile,
} from "../../store/reducers/profile/selectors/getProfile/getProfile";
import {
    getChatsIsLoadingChat
} from "../../store/reducers/chats/selectors/getChatsIsLoadingChat/getChatsIsLoadingChat";
import {
    getRoomsIsLoadingRoom
} from "../../store/reducers/rooms/selectors/getRoomsIsLoadingRoom/getRoomsIsLoadingRoom";
import {
    getSpecialistsFindIsLoading
} from "../../store/reducers/specialistFind/selectors/getSpecialistsFind/getSpecialistsFind";
import {
    getSchedulerIsLoading
} from "../../store/reducers/scheduler/selectors/getSchedulerIsLoading/getSchedulerIsLoading";
import {HeaderNotification} from "../header/headerNotification/HeaderNotification";
import {
    getNotificationsIsLoading
} from "../../store/reducers/notifications/selectors/getNotifications";

export const Navbar = () => {
    const isAuthentication = useAppSelector(getAuthIsAuthentication);
    const [isActiveModal, setIsActiveModal] = useState(false);

    const isShowNavbar = useAppSelector(getNavbarShow);
    const isShowNavbarDesktop = useAppSelector(getShowNavbarDesktop);
    const isNavbarLoading = useAppSelector(getAuthIsNavbarLoading);
    const isProfileIsLoading = useAppSelector(getProfileIsLoadingProfile);
    const isChatsIsLoadingChat = useAppSelector(getChatsIsLoadingChat);
    const isRoomsIsLoadingChat = useAppSelector(getRoomsIsLoadingRoom);
    const isSpecialistsFindLoading = useAppSelector(getSpecialistsFindIsLoading);
    const isCalendarIsLoading = useAppSelector(getSchedulerIsLoading);
    const isNotificationIsLoading = useAppSelector(getNotificationsIsLoading);

    const isShowModal = useAppSelector(getAuthIsShowModal);

    const {t} = useTranslation(["navbar"]);

    useEffect(() => {
        if (isShowModal) {
            setIsActiveModal(true);
        }
    }, [isShowModal]);

    const titlePath: ITitlePath[] = useMemo(() => [
        {title: t("navbar:navbar.navigation.chats"), path: RoutePath.chat_page},
        {title: t("navbar:navbar.navigation.rooms"), path: RoutePath.room_page},
        {title: t("navbar:navbar.navigation.about"), path: RoutePath.about},
        {
            title: t("navbar:navbar.navigation.psychicSelection"),
            path: RoutePath.specialists_find,
            isBtn: true,
        },
        {
            title: t("navbar:navbar.navigation.virtualRehabilitation"),
            path: RoutePath.virtual_rehabilitation,
            isBtn: true,
        },
    ], [t]);

    const showModalHandler = () => {
        setIsActiveModal(true);
    };

    const onCloseModalHandler = () => {
        setIsActiveModal(false);
    };

    const isLoading =
        isNavbarLoading ||
        isProfileIsLoading ||
        isChatsIsLoadingChat ||
        isRoomsIsLoadingChat ||
        isSpecialistsFindLoading ||
        isCalendarIsLoading ||
        isNotificationIsLoading

    const mods: Mods = {
        ["header-out"]: !isShowNavbar || !isShowNavbarDesktop,
    };

    return (
        <>
            <header className={classNames(s.header, mods, ["gilroy", "headerMain"])}>
                <Container variant={"noVerticalPadding"}>
                    <div className={s.header__wrp}>
                        <div className={s.header__menuWrp}>
                            <HeaderLogo/>
                            <div className={s.header__menu}>
                                {titlePath.map((el) => (
                                    <HeaderItem
                                        key={el.path}
                                        title={el.title}
                                        path={el.path}
                                        isBorder={el.isBtn}
                                    />
                                ))}
                            </div>

                            <div className={s.header__options}>
                                {!isAuthentication && (
                                    <CustomButton
                                        onClick={showModalHandler}
                                        variant={"pink"}
                                        size={"w100"}
                                        className={`${s.btnRegistration}`}
                                    >
                                        {t("navbar.registration")}
                                    </CustomButton>
                                )}

                                {isAuthentication && (
                                    <div className={s.header__authBox}>
                                        <HeaderProfile/>
                                        <HeaderNotification/>
                                        <HeaderLogout/>
                                    </div>
                                )}

                                <SwitchLang className={s.switchLang}/>
                            </div>
                        </div>
                        <div className={s.header__options}></div>
                    </div>
                </Container>

                {isLoading && <NavbarLoader className={s.loader}/>}
            </header>

            <LoginModal isOpen={isActiveModal} onClose={onCloseModalHandler}/>
        </>
    );
};
