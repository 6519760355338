import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMessagesProfile} from "../../../models/IMessagesProfile";
import {messagesProfileData} from "../../../utils/data/messagesProfileData";
import {myID} from "../../../utils/data/myID";


const initialState: IMessagesProfile[] = messagesProfileData

export const messageProfileListSlice = createSlice({
    name: 'messageProfile',
    initialState,
    reducers: {
        addMessage(state, action: PayloadAction<{ idChat: string, value: string }>) {
            state.forEach(el => {
                if (el.idChat === action.payload.idChat) {
                    const lastMessages = el.messagesData[el.messagesData.length - 1]
                    lastMessages.messageAuthorID === myID
                        ? lastMessages.messagesList.push({message: action.payload.value, time: '20:28'})
                        : el.messagesData.push({
                            messageAuthorID: myID, messagesList: [
                                {
                                    message: action.payload.value,
                                    time: '20:29'
                                }
                            ]
                        })
                }

            })
        },
        changeActiveChat(state, action: PayloadAction<{ idChat: string }>) {
            return state.map(el => el.idChat === action.payload.idChat
                ?
                {...el, isActive: true}
                :
                {...el, isActive: false})
        },
    },
    extraReducers: () => {
    },
})


export const messageProfileListReducer = messageProfileListSlice.reducer
export const messageProfileListAction = messageProfileListSlice.actions


