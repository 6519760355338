import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISpecialistInfo} from "../../../models/specialist/ISpecialist";


const initialState: ISpecialistInfo = {}

export const specialistInfoSlice = createSlice({
    name: 'specialistInfo',
    initialState,
    reducers: {
        changeFullName(state, action: PayloadAction<{ fullName: string }>) {
            state.fullName = action.payload.fullName
        },
        changeCity(state, action: PayloadAction<{ city: string }>) {
            state.city = action.payload.city
        },
        changeTelegram(state, action: PayloadAction<{ telegram: string }>) {
            state.telegram = action.payload.telegram
        },
        changeLanguage(state, action: PayloadAction<{ values: string[]}>) {
            state.language = action.payload.values
        },
        changePhone(state, action: PayloadAction<{ telephone: string }>) {
            state.telephone = action.payload.telephone
        },
        changeAboutMe(state, action: PayloadAction<{ aboutMe: string }>) {
            state.aboutMe = action.payload.aboutMe
        },
        changeSpecialization(state, action: PayloadAction<{ specialization: string }>) {
            state.specialization = action.payload.specialization
        },
        changeWorkExperience(state, action: PayloadAction<{ workExperience: string }>) {
            state.workExperience = action.payload.workExperience
        },
        changePriceSingle(state, action: PayloadAction<{ priceSingle: string }>) {
            state.priceSingle = action.payload.priceSingle
        },
        changePriceGroup(state, action: PayloadAction<{ priceGroup: string }>) {
            state.priceGroup = action.payload.priceGroup
        },
    },
    extraReducers: () => {
    },
})


export const specialistInfoReducer = specialistInfoSlice.reducer
export const specialistInfoAction = specialistInfoSlice.actions


