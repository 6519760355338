import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from '../../../config/StateSchema';
import {snackbarAction} from '../../../errors/SnackbarSlice';
import {BaseResponse} from "../../../../api/api";


interface IPayload {
    push_user_id: string
}

export const linkNotificationsPush = createAsyncThunk<
    void,
    IPayload,
    ThunkConfig<string>
>(
    'notifications/linkNotificationsPush',
    async ({push_user_id}, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;

        try {
            const response = await extra.api.post<BaseResponse>(
                `notification/link-push-user-id`, {
                    push_user_id
                });
            if (!response.data.success) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error')
            }

        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            console.log('err', err)
            // @ts-ignore
            return rejectWithValue(err.data.error);
        }
    },
);
