import {Navbar} from "../Navbar";
import {NavbarBurger} from "../NavbarBurger/NavbarBurger";
import {useCallback, useEffect, useRef, useState} from "react";
import {useThrottle} from "../../../hooks/useThrottle";
import {navbarAction} from "../../../store/reducers/navbar/navbarSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks/redux";
import {
    getShowNavbarDesktop
} from "../../../store/reducers/navbar/getShowNavbarDesktop/getShowNavbarDesktop";
import LinearProgress from "@mui/material/LinearProgress";

export const NavbarBox = () => {
    const {changeShowNavbarDesktop} = navbarAction
    const isShowNavbarDesktop = useAppSelector(getShowNavbarDesktop)
    const dispatch = useAppDispatch()
    const [scroll, setScroll] = useState(0);
    const newScroll = useRef<number>(0)

    //Логика для скрытия / показа навбара при скролле
    const changeShowNavbarHandler = useCallback((isShow: boolean) => {
        dispatch(changeShowNavbarDesktop(isShow))
    }, [changeShowNavbarDesktop, dispatch])

    const handleScroll = useCallback(() => {
        setScroll(window.scrollY);
    }, [])

    const throttleScroll = useThrottle(handleScroll, 50)

    // useEffect(() => {
    //     window.addEventListener("scroll", throttleScroll);
    //     return () => window.removeEventListener("scroll", throttleScroll);
    // }, [scroll, handleScroll, throttleScroll]);

    // useEffect(() => {
    //     //при прокрутке вверх, появляется навбар
    //     if (newScroll.current > scroll) {
    //         !isShowNavbarDesktop && changeShowNavbarHandler(true)
    //     } else {
    //         if (scroll >= 280 && scroll !== newScroll.current) {
    //             isShowNavbarDesktop && changeShowNavbarHandler(false)
    //         } else {
    //             !isShowNavbarDesktop && changeShowNavbarHandler(true)
    //         }
    //     }
    //     newScroll.current = scroll
    // }, [changeShowNavbarHandler, isShowNavbarDesktop, scroll])


    return (
        <>
            <Navbar/>
            <NavbarBurger/>

        </>
    );
};
