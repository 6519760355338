import React, {Dispatch, memo, SetStateAction, useEffect, useMemo} from 'react';
import s from './NavbarMenuBurger.module.css'
import {classNames} from "../../../../utils/helpers/classNames/classNames";
import {HeaderItemBurger} from "./headerItemBurger/HeaderItemBurger";
import {HeaderLogout} from "../../../header/headerLogout/HeaderLogout";
import {SwitchLang} from "../../switchLang/SwitchLang";
import {useTranslation} from "react-i18next";
import {ITitlePath} from "../../../../models/ITitlePath";
import {RoutePath} from "../../../../router/routerConfig/routerConfig";
import {CSSTransition} from "react-transition-group";
import {CustomButton} from "../../../../ui/Button/CustomButton";
import {useTouchUp} from "../../../../hooks/useTouchUp";

type NavbarMenuBurgerProps = {
    isShow: boolean
    setIsShow: Dispatch<SetStateAction<boolean>>
    isAuthentication?: boolean
    showModalLogin: () => void
    onClose: () => void
}

export const NavbarMenuBurger = memo((props: NavbarMenuBurgerProps) => {
    const {
        isShow,
        setIsShow,
        isAuthentication = false,
        showModalLogin,
        onClose
    } = props

    const {t} = useTranslation('navbar')

    const titlePath: ITitlePath[] = useMemo(()=>[
        {title: t('navbar:navbar.navigation.chats'), path: RoutePath.chat_page},
        {title: t('navbar:navbar.navigation.rooms'), path: RoutePath.room_page},
        {title: t('navbar:navbar.navigation.about'), path: RoutePath.about},
        {
            title: t('navbar:navbar.navigation.psychicSelection'),
            path: RoutePath.specialists_find,
            isBtn: true
        },
        {
            title: t('navbar:navbar.navigation.virtualRehabilitation'),
            path: RoutePath.virtual_rehabilitation,
            isBtn: true
        },
    ], [t])

    const {onTouchStart, onTouchMove, onTouchEnd, isSwapUp} = useTouchUp()
    useEffect(() => {
        if (isSwapUp) {
            onClose()
        }
    }, [isSwapUp, onClose])

    return (
        <CSSTransition timeout={400}
            in={isShow}
            classNames={'menuBurger'}
            mountOnEnter
            unmountOnExit>
            <nav className={classNames(s.header__menuBurger, {}, [])}>
                <div
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    style={{height: '100%'}}
                >
                    <ul className={s.header__listBurger}>
                        {titlePath.map(el => (
                            <li key={el.title}>
                                <HeaderItemBurger title={el.title}
                                    path={el.path}
                                    setIsShow={setIsShow}/>
                            </li>
                        ))}

                        {isAuthentication
                            ?   (
                                <li>
                                    <HeaderLogout title={t('navbar:navbar.navigation.logout')}/>
                                </li>
                            )

                            : (
                                <li className={s.btn__authBurger}>
                                    <CustomButton
                                        onClick={showModalLogin}
                                        variant={"pink"}
                                        size={'medium'}
                                    >
                                        {t('navbar.registration')}
                                    </CustomButton>
                                </li>)}

                        <li className={s.language_block}>
                            <SwitchLang className={s.switchLang}/>
                        </li>
                    </ul>
                </div>
            </nav>
        </CSSTransition>
    );
});
