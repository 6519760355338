import {IMessagesProfile} from "../../models/IMessagesProfile";
import {myID} from "./myID";
import uuid from "react-uuid";

import img1 from '../../utils/images/avatars/01.svg'
import img2 from '../../utils/images/avatars/02.svg'
import img3 from '../../utils/images/avatars/03.svg'
import img4 from '../../utils/images/avatars/04.svg'
import img5 from '../../utils/images/avatars/05.svg'
import img6 from '../../utils/images/avatars/06.svg'
import img7 from '../../utils/images/avatars/07.svg'
import img8 from '../../utils/images/avatars/08.svg'
import img9 from '../../utils/images/avatars/09.svg'
import img10 from '../../utils/images/avatars/010.svg'

const userID_1 = uuid()
const userID_2 = uuid()
const userID_3 = uuid()
const userID_4 = uuid()
const userID_5 = uuid()
const userID_6 = uuid()
const userID_7 = uuid()
const userID_8 = uuid()
const userID_9 = uuid()
const userID_10 = uuid()


export const messagesProfileData: IMessagesProfile[] = [
    {
        idChat: uuid(),
        avatarUserIMG: img1,
        nicknameUser: 'Petr',
        isOnlineUser: true,
        idUser: userID_1,
        lastMessageTime: '15:26',
        isActive: true,
        messagesData: [
            {
                messageAuthorID: userID_1,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img2,
        nicknameUser: 'Basil',
        isOnlineUser: false,
        idUser: userID_2,
        lastMessageTime: '12:26',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_2,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_2,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }, {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },

                ]
            },

            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }, {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },

                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img3,
        nicknameUser: 'Marina',
        isOnlineUser: true,
        idUser: userID_3,
        lastMessageTime: '12:55',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_3,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                ]
            },
            {
                messageAuthorID: userID_3,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img4,
        nicknameUser: 'Michael',
        isOnlineUser: true,
        idUser: userID_4,
        lastMessageTime: '11:56',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_4,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img5,
        nicknameUser: 'Catherine',
        isOnlineUser: false,
        idUser: userID_5,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_5,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_5,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img6,
        nicknameUser: 'Eugene',
        isOnlineUser: false,
        idUser: userID_6,
        lastMessageTime: '16:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_6,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_6,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img7,
        nicknameUser: 'Victoria',
        isOnlineUser: false,
        idUser: userID_7,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_7,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_7,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img8,
        nicknameUser: 'Alice',
        isOnlineUser: true,
        idUser: userID_8,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_8,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_8,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img9,
        nicknameUser: 'Catherine',
        isOnlineUser: false,
        idUser: userID_9,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_9,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_9,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img10,
        nicknameUser: 'Alexander',
        isOnlineUser: true,
        idUser: userID_10,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_10,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_10,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img1,
        nicknameUser: 'Petr',
        isOnlineUser: true,
        idUser: userID_1,
        lastMessageTime: '15:26',
        isActive: true,
        messagesData: [
            {
                messageAuthorID: userID_1,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img2,
        nicknameUser: 'Basil',
        isOnlineUser: false,
        idUser: userID_2,
        lastMessageTime: '12:26',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_2,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_2,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }, {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },

                ]
            },

            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }, {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },

                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img3,
        nicknameUser: 'Marina',
        isOnlineUser: true,
        idUser: userID_3,
        lastMessageTime: '12:55',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_3,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                ]
            },
            {
                messageAuthorID: userID_3,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img4,
        nicknameUser: 'Michael',
        isOnlineUser: true,
        idUser: userID_4,
        lastMessageTime: '11:56',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_4,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img5,
        nicknameUser: 'Catherine',
        isOnlineUser: false,
        idUser: userID_5,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_5,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_5,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img6,
        nicknameUser: 'Eugene',
        isOnlineUser: false,
        idUser: userID_6,
        lastMessageTime: '16:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_6,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_6,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img7,
        nicknameUser: 'Victoria',
        isOnlineUser: false,
        idUser: userID_7,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_7,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_7,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img8,
        nicknameUser: 'Alice',
        isOnlineUser: true,
        idUser: userID_8,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_8,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_8,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img9,
        nicknameUser: 'Catherine',
        isOnlineUser: false,
        idUser: userID_9,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_9,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_9,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },
    {
        idChat: uuid(),
        avatarUserIMG: img10,
        nicknameUser: 'Alexander',
        isOnlineUser: true,
        idUser: userID_10,
        lastMessageTime: '19:33',
        isActive: false,
        messagesData: [
            {
                messageAuthorID: userID_10,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '15:46'
                    }
                ]
            },
            {
                messageAuthorID: myID,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
            {
                messageAuthorID: userID_10,
                messagesList: [
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    },
                    {
                        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        time: '16:46'
                    }
                ]
            },
        ]
    },

]
