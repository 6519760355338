import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {Message} from "@twilio/conversations";
import {
    getTwilioChatsActiveTwilioToken,
    getTwilioChatsPersonalChatsTokens
} from "../../selectors/getTwilioChats/getTwilioChats";
import {twilioChatsAction} from "../../twilioChatsSlice";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {notificationsAction} from "../../../notifications/notificationsSlice";

export const addNewMessage = createAsyncThunk<
    void,
    Message,
    ThunkConfig<string>
>(
    'twilioChat/addNewMessage',
    async (message, thunkAPI) => {
        const {dispatch, getState} = thunkAPI;
        const activeTwilioToken = getTwilioChatsActiveTwilioToken(getState())
        const personalChatsTokens = getTwilioChatsPersonalChatsTokens(getState())
        try {
            if (message.conversation.sid === activeTwilioToken) {
                dispatch(twilioChatsAction.addNewMessage(message))
            } else if (personalChatsTokens?.some( el => el.token ===  message.conversation.sid)) {
                dispatch(twilioChatsAction.setNewNumberOFMessage(message.conversation.sid))
                dispatch(snackbarAction.addNewNotificationPrivateMessage({
                    event: 'CHAT_PRIVATE_NEW_MESSAGE'
                }))
                dispatch(notificationsAction.addNotificationPersonalMessage({
                    event: 'CHAT_PRIVATE_NEW_MESSAGE'
                }))
            }
        } catch (err) {
            console.log(err)
        }
    },
);
