import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {tagsFilterReducer} from "./reducers/tagsFilter/tagsFilterSlice";
import {roomItemsReducer} from "./reducers/roomItem/roomItemSlice";
import {languageReducer} from "./reducers/language/languageSlice";
import {messageDialogsReducer} from "./reducers/messageDialogs/messageDialogsSlice";
import {messageProfileListReducer} from "./reducers/messageProfileList/messageProfileListSlice";
import {authReducer} from "./reducers/auth/authSlice";
import {userInfoReducer} from "./reducers/userInfo/userInfoSlice";
import {specialistInfoReducer} from "./reducers/specialistInfo/specialistInfoSlice";
import {profileReducer} from "./reducers/profile/profileSlice";
import {testReducer} from "./reducers/test/testSlice";
import {navbarReducer} from "./reducers/navbar/navbarSlice";
import {schedulerReducer} from "./reducers/scheduler/schedulerSlice";
import {footerReducer} from "./reducers/footer/footerSlice";
import {twilioCallsReducer} from "./reducers/twilioCalls/twilioCallsSlice";
import {AxiosInstance} from 'axios';
import {extraArg} from "./reducers/config/StateSchema";
import {constantsReducer} from "./reducers/constants/constantsSlice";
import {chatsReducer} from "./reducers/chats/chatsSlice";
import {chatItemReducer} from "./reducers/chatItem/chatItemSlice";
import {profileInfoReducer} from "./reducers/profileInfo/profileInfoSlice";
import {roomsReducer} from "./reducers/rooms/roomsSlice";
import {specialistsFindSliceReducer} from "./reducers/specialistFind/specialistsFindSlice";
import {snackbarReducer} from "./reducers/errors/SnackbarSlice";
import {notificationsReducer} from "./reducers/notifications/notificationsSlice";
import {personalChatsReducer} from "./reducers/privateСhats/personalСhats";
import {twilioChatsReducer} from "./reducers/twilioChats/twilioChatsSlice";

export interface ThunkExtraArg {
    api: AxiosInstance
}

const rootReducer = combineReducers({
    tagsFilterReducer,
    roomItemsReducer,
    authReducer,
    profileReducer,
    userInfoReducer,
    languageReducer,
    messageProfileListReducer,
    messageDialogsReducer,
    specialistInfoReducer,
    testReducer,
    navbarReducer,
    schedulerReducer,
    footerReducer,
    twilioCallsReducer,
    constantsReducer,
    chatsReducer,
    chatItemReducer,
    profileInfoReducer,
    roomsReducer,
    specialistsFindSliceReducer,
    snackbarReducer,
    notificationsReducer,
    personalChatsReducer,
    twilioChatsReducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: {
                extraArgument: extraArg,
            },
            serializableCheck: false
        }
        )
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

