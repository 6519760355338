import s from "./FormAuthentication.module.css";
import { InputEffect } from "../../ui/inputTextareaEffect/InputEffec";
import { ChangeEvent, useState } from "react";
import { CustomButton } from "../../ui/Button/CustomButton";

export const AUTHENTICATION_LOCAL_STORAGE_LOGIN = "login";
export const AUTHENTICATION_LOCAL_STORAGE_PASSWORD = 'password'

type ValuesData = {
    login: string
    password: string

}

export const FormAuthentication = ({setIsUSer}: {setIsUSer: (isUser: boolean) => void}) => {
    const [valuesData, setValuesData] = useState<ValuesData>({
        login: '',
        password: ''
    })

    const [error, setError] = useState('')

    const onClickHandler = () => {
        if (valuesData.login === 'dev' && valuesData.password === 'arVE0nY91q9P'
            || valuesData.login === 'viewer' && valuesData.password === 'UKZV9vCBTykR') {
            localStorage.setItem(AUTHENTICATION_LOCAL_STORAGE_LOGIN, valuesData.login)
            localStorage.setItem(AUTHENTICATION_LOCAL_STORAGE_PASSWORD, valuesData.password)
            setIsUSer(true)
            setError('')
        } else {
            setError('incorrect username or password')
        }
    }

    const onChangeLogin = (event: ChangeEvent<HTMLInputElement>) => {
        setValuesData({...valuesData, login: event.target.value})
    }

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setValuesData({...valuesData, password: event.target.value})
    }
    return (
        <div className={s.container}>
            <form className={s.form}>
                <InputEffect title={'login'} value={valuesData.login} onBlur={() => {
                }} onChange={onChangeLogin}/>
                <InputEffect title={'password'} value={valuesData.password} onBlur={() => {
                }} onChange={onChangePassword}/>
                {error &&
                    <span className={s.error}>{error}</span>}
                <CustomButton onClick={onClickHandler}>
                    Login
                </CustomButton>
            </form>
        </div>
    );
};
