import {$api} from "../../api/api";
import {RootState, ThunkExtraArg} from "../../store";

export const extraArg: ThunkExtraArg = {
    api: $api,
};

export interface ThunkConfig<T> {
    rejectValue: T
    extra: ThunkExtraArg
    state: RootState
}
