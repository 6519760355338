import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUserInfo} from "../../../models/user/IUserProfile";


const initialState: IUserInfo = {}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        changeCity(state, action: PayloadAction<{ city: string }>) {
            state.city = action.payload.city
        },
        changeTelegram(state, action: PayloadAction<{ telegram: string }>) {
            state.telegram = action.payload.telegram
        },
        changePhone(state, action: PayloadAction<{ telephone: string }>) {
            state.telephone = action.payload.telephone
        },
        changeAboutMe(state, action: PayloadAction<{ aboutMe: string }>) {
            state.aboutMe = action.payload.aboutMe
        },
    },
    extraReducers: () => {
    },
})


export const userInfoReducer = userInfoSlice.reducer
export const userInfoAction = userInfoSlice.actions


  
