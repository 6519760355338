import {Dispatch, memo, SetStateAction} from 'react';
import s from './HeaderItemBurger.module.css'
import {ITitlePath} from "../../../../../models/ITitlePath";
import {NavLink} from "react-router-dom";
import {goToTop} from "../../../../../utils/helpers/goToTop";

type HeaderItemBurgerPropsType = ITitlePath & {
    setIsShow: Dispatch<SetStateAction<boolean>>
}

export const HeaderItemBurger = memo(({title, path, setIsShow}: HeaderItemBurgerPropsType) => {

    const handleChangeIsShow = () => {
        setIsShow(false)
        goToTop("auto")
    }
    return (
        <div className={s.header__item}>
            <NavLink
                to={path}
                className={s.header__link}
                onClick={handleChangeIsShow}>
                {title}
            </NavLink>
        </div>
    );
});
