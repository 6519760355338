import s from './Footer.module.css'
import {Container} from "../../../common/contaiiner/Container";
import {NavLink} from "react-router-dom";
import {footerPages} from "../../../utils/data/footerData";
import {useTranslation} from "react-i18next";
import {HeaderLogo} from "../../header/headerLogo/HeaderLogo";
import {RoutePath} from "../../../router/routerConfig/routerConfig";
import {goToTop} from "../../../utils/helpers/goToTop";
import {useCallback} from "react";

export const Footer = () => {
    const {t} = useTranslation(['footer'])

    const onClickHandler = useCallback(() => {
        goToTop('auto')
    }, [])

    return (
        <footer className={s.footer}>
            <Container variant={'noVerticalPadding'}>
                <div className={s.footer__topLine}>
                    <HeaderLogo className={s.footer__logoLink}/>
                    <div className={s.topLineMenu}>
                        <NavLink
                            to={RoutePath.about}
                            className={s.topLineMenu__link}
                            onClick={onClickHandler}
                        >
                            {t('about')}
                        </NavLink>
                        <NavLink
                            to={RoutePath.mission}
                            className={s.topLineMenu__link}
                            onClick={onClickHandler}
                        >
                            {t('ourMission')}
                        </NavLink>
                    </div>
                </div>

                <div className={s.footer__bottomLine}>
                    <div className={s.footer__copyright}>
                        OpenHalt © 2023
                    </div>

                    <div className={s.bottomLineMenu}>
                        {footerPages.map((page, i) => <NavLink
                            key={page.title}
                            to={page.path}
                            onClick={onClickHandler}
                            className={s.bottomLineMenu__link}>
                            {t(`titles.${i}`)}
                        </NavLink>)}

                    </div>
                </div>

            </Container>
        </footer>
    );
};
