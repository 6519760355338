import React, {useCallback, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks/redux';
import {getLanguage} from "../../store/reducers/language/getLanguege/getLanguage";
import {getAuthIsAuthentication} from "../../store/reducers/auth/selectors/getAuth/getAuth";
import {languageAction} from "../../store/reducers/language/languageSlice";
import {getCookieToken} from "../../store/reducers/auth/services/tokenManagement/cookieToken";
import i18n from "i18next";
import {io, Socket} from "socket.io-client";
import {
    BaseResponseNotification
} from "../../store/reducers/notifications/types/notificationsTypes";
import {notificationsAction} from "../../store/reducers/notifications/notificationsSlice";
import {snackbarAction} from "../../store/reducers/errors/SnackbarSlice";
import {
    fetchNotificationsCollection
} from "../../store/reducers/notifications/services/fetchNotificationsCollection/fetchNotificationsCollection";
import {fetchAuthGuest} from "../../store/reducers/auth/services/fetchAuthGuest/fetchAuthGuest";
import {
    fetchTwilioGuestToken
} from "../../store/reducers/chatItem/services/fetchTwilioGuestToken/fetchTwilioGuestToken";
import {
    fetchNotificationsRequests
} from "../../store/reducers/notifications/services/fetchNotificationsRequests/fetchNotificationsRequests";
import {NavbarBox} from "../navbar/NavbarBox/NavbarBox";
import {PrivateRoute} from "../../router/PrivateRoute";
import {FooterBox} from "../footer/FooterBox";
import {Snackbar} from "../../ui/Snackbar/Snackbar";
import {
    linkNotificationsPush
} from "../../store/reducers/notifications/services/linkNotificationsPush/linkNotificationsPush";
import {Client, Message} from "@twilio/conversations";
import {twilioChatsAction} from "../../store/reducers/twilioChats/twilioChatsSlice";
import {
    fetchMyPrivateChatsTokens
} from "../../store/reducers/twilioChats/services/fetchMyPrivateChatsTokens/fetchMyPrivateChatsTokens";
import {
    getTwilioChatsPersonalToken
} from "../../store/reducers/twilioChats/selectors/getTwilioChats/getTwilioChats";
import {addNewMessage} from "../../store/reducers/twilioChats/services/addNewMessage/addNewMessage";
import {fetchConstants} from "../../store/reducers/constants/services/fetchConstants";

export const AppLoader = () => {
    const socketRef = useRef<Socket | null>(null)
    const clientRef = useRef<Client | null>(null)
    const dispatch = useAppDispatch()
    const language = useAppSelector(getLanguage)
    const isAuth = useAppSelector(getAuthIsAuthentication)
    const {changeLanguage} = languageAction
    const twilioChatToken = useAppSelector(getTwilioChatsPersonalToken)

    useEffect(() => {
        if (language !== null)
            i18n.changeLanguage(language)
    }, [language])

    useEffect(() => {
        fetchConstants(dispatch)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let valueString = localStorage.getItem('i18nextLng')
        if (valueString === 'ru' || valueString === 'en') {
            if (valueString === language) {
                return
            }
            dispatch(changeLanguage({language: valueString}))
        }
    }, [changeLanguage, dispatch, language])

    useEffect(() => {
        if (isAuth) {
            // @ts-ignore
            const OneSignal = window.OneSignal = window.OneSignal || [];
            OneSignal.push(function () {
                OneSignal.init({
                    appId: 'efa659c0-5367-40ec-8e65-066243e524aa',
                    safari_web_id: 'web.onesignal.auto.4345f3b9-0648-4376-838e-008c1d802430',
                    notifyButton: {
                        enable: false,
                    },
                })

                OneSignal.getUserId().then(function (userId: string) {
                    if (userId == null) {
                        console.error("no user_id provided");
                    } else {
                        dispatch(linkNotificationsPush({
                            push_user_id: userId
                        }))
                    }
                })
            });
        }
    }, [dispatch, isAuth])


    const addNewNotification = useCallback((data: BaseResponseNotification) => {
        if (data.success) {
            dispatch(notificationsAction.addNewNotification(data.response.notification))
            dispatch(snackbarAction.addNewNotification(data.response.notification))
        }
    }, [dispatch])


    const handleMessageAdded = useCallback((message: Message) => {
        dispatch(addNewMessage(message))
    }, [dispatch]);

    const createNewClient = useCallback((twilioChatToken: string) => {
        clientRef.current = new Client(twilioChatToken)
        dispatch(twilioChatsAction.setClient(clientRef.current))
        if (isAuth) {
            dispatch(fetchMyPrivateChatsTokens({
                is_personal: 1,
                user_role: 'all',
            }))
        }
        clientRef.current?.on("messageAdded", handleMessageAdded)
    }, [dispatch, handleMessageAdded, isAuth])

    const deleteClient = useCallback(() => {
        clientRef?.current?.off("messageAdded", handleMessageAdded);
        clientRef.current?.shutdown();
        clientRef.current = null
        dispatch(twilioChatsAction.clearClient())
    }, [dispatch, handleMessageAdded])


    //twilio user
    useEffect(() => {
        if (twilioChatToken && isAuth) {
            deleteClient()
            createNewClient(twilioChatToken)
        }
        if (!isAuth) {
            deleteClient()
        }
    }, [createNewClient, deleteClient, dispatch, isAuth, twilioChatToken])

    // useEffect(() => {
    //     if (activeTwilioToken && clientRef?.current) {
    //         try {
    //             (async () => {
    //                 const chanel = await clientRef.current?.getConversationBySid(activeTwilioToken)
    //                 dispatch(twilioChatsAction.setConversation(chanel))
    //             })()
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     }
    //     if (!activeTwilioToken || !clientRef?.current) {
    //         dispatch(twilioChatsAction.setConversation(undefined))
    //     }
    // }, [activeTwilioToken, dispatch])

    const connect = useCallback(() => {
        console.log('Connected to server')
    }, [])

    const disconnect = useCallback(() => {
        console.log('Disconnect to server')
    }, [])

    const wsConnect = useCallback(() => {
        socketRef.current?.on('connect', connect);
        socketRef.current?.on('message', addNewNotification);
        socketRef.current?.on('disconnect', disconnect);
        socketRef.current?.connect()
    }, [addNewNotification, connect, disconnect])

    const wsDisconnect = useCallback(() => {
        socketRef.current?.off('disconnect', disconnect);
        socketRef.current?.off('connect', connect);
        socketRef.current?.off('message', addNewNotification);
        socketRef.current?.disconnect();
        socketRef.current = null
    }, [addNewNotification, connect, disconnect])

    useEffect(() => {
        if (isAuth) {
            (async () => {
                const token = await getCookieToken();
                dispatch(fetchNotificationsCollection({}))
                const location = window.location.hostname.includes('localhost')
                const URL = location ? 'http://localhost:4000' : '';
                socketRef.current = io(URL, {
                    query: {
                        token
                    }
                });
                if (token) {
                    wsConnect()
                }
            })()
        }

        return () => {
            console.log('end')
            wsDisconnect()
        };
    }, [dispatch, isAuth, wsConnect, wsDisconnect]);

    //guest
    useEffect(() => {

        (async () => {
            if (!isAuth) {
                const token = await fetchAuthGuest(dispatch)
                if (token) {
                    const twilioGuestToken = await fetchTwilioGuestToken()
                    if (twilioGuestToken) {
                        createNewClient(twilioGuestToken)
                    }

                }
            }
        })()
    }, [createNewClient, dispatch, isAuth]);

    useEffect(() => {
        if (isAuth) {
            fetchNotificationsRequests(dispatch)
        }

        return () => {
            dispatch(notificationsAction.clearNotificationsData())
        }
    }, [isAuth, dispatch])

    return (
        <>
            <NavbarBox/>
            <PrivateRoute/>
            <FooterBox/>
            <Snackbar/>
        </>
    )
}
