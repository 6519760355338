import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks/redux";
import { Loader } from "./components/loader/Loader";
import { fetchProfileSelf } from "./store/reducers/profile/services/fetchProfileSelf/fetchProfileSelf";
import { getAuthIsMainLoading } from "./store/reducers/auth/selectors/getAuth/getAuth";
import { getCookieToken } from "./store/reducers/auth/services/tokenManagement/cookieToken";
import { setToken } from "./store/reducers/auth/services/tokenManagement/tokenManagement";
import { AppLoader } from "./components/AppLoader/AppLoader";
import {
    AUTHENTICATION_LOCAL_STORAGE_LOGIN,
    AUTHENTICATION_LOCAL_STORAGE_PASSWORD,
    FormAuthentication,
} from "./components/FormAuthentication/FormAuthentication";
import { twilioChatsAction } from "./store/reducers/twilioChats/twilioChatsSlice";
import { authAction } from "./store/reducers/auth/authSlice";

function App() {
    const dispatch = useAppDispatch();
    const isMainLoading = useAppSelector(getAuthIsMainLoading);
    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
        (async () => {
            const token = await getCookieToken();

            if (token) {
                dispatch(twilioChatsAction.setPersonalTwilioToken(token));
                await setToken(token);
                await dispatch(fetchProfileSelf(token));
            } else {
                dispatch(authAction.changeIsNavbarLoading(false));
                dispatch(authAction.changeIsMainLoading(false));
            }
        })();
    //eslint-disable-next-line
  }, []);

    useEffect(() => {
        const login = localStorage.getItem(AUTHENTICATION_LOCAL_STORAGE_LOGIN);
        const password = localStorage.getItem(
            AUTHENTICATION_LOCAL_STORAGE_PASSWORD
        );
        if (
            (login === "dev" && password === "arVE0nY91q9P") ||
      (login === "viewer" && password === "UKZV9vCBTykR")
        ) {
            setIsUser(true);
        }
    //eslint-disable-next-line
  }, []);

    if (isMainLoading) {
        return <Loader />;
    }

    if (!isUser) {
        return <FormAuthentication setIsUSer={setIsUser} />;
    }

    return (
        <div className="wrapper">
            <AppLoader />
        </div>
    );
}

export default App;
