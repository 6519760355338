import s from "./NavbarBurgerMainBox.module.css";
import { classNames } from "../../../../../utils/helpers/classNames/classNames";
import { HeaderLogo } from "../../../../header/headerLogo/HeaderLogo";
import { HeaderProfile } from "../../../../header/headerProfile/HeaderProfile";
import { memo } from "react";
import { CustomButton } from "../../../../../ui/Button/CustomButton";
import { useTranslation } from "react-i18next";
import { HeaderNotification } from "../../../../header/headerNotification/HeaderNotification";

interface NavbarBurgerMainBoxProps {
  className?: string;
  isAuthentication?: boolean;
  isSpecialist?: boolean;
  closeBurgerMenu: () => void;
  showModalLogin: () => void;
  showBurgerMenu: () => void;
  isShow?: boolean;
}

export const NavbarBurgerMainBox = memo((props: NavbarBurgerMainBoxProps) => {
    const {
        isAuthentication = false,
        className,
        closeBurgerMenu,
        showModalLogin,
        showBurgerMenu,
        isShow = false,
    } = props

    const {t} = useTranslation(['navbar'])

    return (
        <div className={classNames(s.containerBurger, {}, [className])}

        >
            <div className={s.header__bodyBurger}>

                <HeaderLogo/>

                <div className={s.header__btnsBurger}>

                    {isAuthentication
                        ? <>
                            <HeaderProfile
                                closeMenuBurger={closeBurgerMenu}/>
                            <HeaderNotification
                                closeMenuBurger={closeBurgerMenu}/>
                        </>

                        : <div className={s.header_btn__authBurger}>
                            <CustomButton
                                onClick={showModalLogin}
                                variant={"pink"}
                                size={'w100'}
                                className={`${s.btnRegistration}`}
                            >
                                {t('navbar.registration')}
                            </CustomButton>
                        </div>
                    }


                </div>
                <div onClick={showBurgerMenu}
                    className={`${s.header__burger} ${isShow ? s.active : ''}`}>
                    <span></span>
                </div>

            </div>
        </div>
    );
});
