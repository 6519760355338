import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchConstUserStatus} from "./services/fetchConstUserStatus/fetchConstUserStatus";
import {IConstantCollection, IConstants} from "./types/constantsTypes";
import {
    fetchConstInterestAddiction
} from "./services/fetchConstInterestAddiction/fetchConstInterestAddiction";
import {
    fetchConstInterestProblems
} from "./services/fetchConstInterestProblems/fetchConstInterestProblems";

//addictions svg
import addiction from '../../../utils/images/interests/drugs.svg'
import alcoholism from '../../../utils/images/interests/alco.svg'
import ludomania from '../../../utils/images/interests/ludo.svg'
import gamblingAddiction from '../../../utils/images/interests/gambling.svg'
import coDependence from '../../../utils/images/interests/co-dep.svg'

//problems svg
import insomnia from '../../../utils/images/interests/insomnia.svg'
import bullying from '../../../utils/images/interests/bullying.svg'
import depression from '../../../utils/images/interests/depression.svg'
import kleptomania from '../../../utils/images/interests/klepto.svg'
import uncertainty from '../../../utils/images/interests/uncertainty.svg'
import offense from '../../../utils/images/interests/resentment.svg'
import loneliness from '../../../utils/images/interests/lonely.svg'
import PTSD from '../../../utils/images/interests/ptsd.svg'
import panicAttacks from '../../../utils/images/interests/panic.svg'
import eatingDisorder from '../../../utils/images/interests/eating.svg'
import anxiety from '../../../utils/images/interests/anxiety.svg'
import workaholism from '../../../utils/images/interests/workhard.svg'
import lossLovedOne from '../../../utils/images/interests/loss.svg'
import phobiasAllergies from '../../../utils/images/interests/fobia.svg'
import senseFear from '../../../utils/images/interests/fear.svg'


type ObjType = {
    [key: string]: string;
}

const addictionsSVG: ObjType = {
    'addiction': addiction,
    'alcoholism': alcoholism,
    'ludomania': ludomania,
    'gamblingAddiction': gamblingAddiction,
    'coDependence': coDependence,
}

const problemsSVG: ObjType = {
    'insomnia': insomnia,
    'bullying': bullying,
    'depression': depression,
    'kleptomania': kleptomania,
    'uncertainty': uncertainty,
    'offense': offense,
    'loneliness': loneliness,
    'PTSD': PTSD,
    'panicAttacks': panicAttacks,
    'eatingDisorder': eatingDisorder,
    'anxiety': anxiety,
    'workaholism': workaholism,
    'lossLovedOne': lossLovedOne,
    'phobiasAllergies': phobiasAllergies,
    'senseFear': senseFear,
}


const initialState: IConstants = {
    interestAddiction: {
        error: undefined
    },
    interestProblem: {
        error: undefined
    },
    userStatus: {
        error: undefined
    }
}

export const constantsSlice = createSlice({
    name: 'constants',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            //userStatus
            .addCase(fetchConstUserStatus.pending, (state) => {
                state.userStatus.error = undefined
            })
            .addCase(fetchConstUserStatus.fulfilled, (state, action: PayloadAction<IConstantCollection[]>) => {
                state.userStatus.collection = action.payload
            })
            .addCase(fetchConstUserStatus.rejected, (state, action) => {
                state.userStatus.error = action.payload
            })

            //addiction
            .addCase(fetchConstInterestAddiction.pending, (state) => {
                state.interestAddiction.error = undefined
            })
            .addCase(fetchConstInterestAddiction.fulfilled, (state, action: PayloadAction<IConstantCollection[]>) => {
                state.interestAddiction.collection = action.payload.map(addiction => (
                    {
                        id: addiction.id,
                        lang_key: addiction.lang_key,
                        img: addictionsSVG[addiction.lang_key] || addiction as unknown as string
                    }
                ))
            })
            .addCase(fetchConstInterestAddiction.rejected, (state, action) => {
                state.interestAddiction.error = action.payload
            })

            //problems
            .addCase(fetchConstInterestProblems.pending, (state) => {
                state.interestProblem.error = undefined
            })
            .addCase(fetchConstInterestProblems.fulfilled, (state, action: PayloadAction<IConstantCollection[]>) => {
                state.interestProblem.collection = action.payload.map(problem => (
                    {
                        id: problem.id,
                        lang_key: problem.lang_key,
                        img: problemsSVG[problem.lang_key] || insomnia as unknown as string
                    }
                ))
            })
            .addCase(fetchConstInterestProblems.rejected, (state, action) => {
                state.interestProblem.error = action.payload
            })


    },
})


export const constantsReducer = constantsSlice.reducer
export const constantsAction = constantsSlice.actions


