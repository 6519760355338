import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDialogItem} from "../../../models/IDialog";
import uuid from "react-uuid";


const initialState: IDialogItem[] = []

interface IUser {
  nickname: string
  userPic: string
  id: number
}

export const messageDialogsSlice = createSlice({
    name: 'messageDialogs',
    initialState,
    reducers: {
        addMessage(state, action: PayloadAction<{ user: IUser, message: string, timeCreatMessage: string }>) {
            return [...state, {
                message: action.payload.message,
                timeCreatMessage: action.payload.timeCreatMessage,
                idMessage: uuid(),
                idUser: action.payload.user.id,
                // name: action.payload.user.nickname,
                // idMessage: uuid(),
                // imgSRC: action.payload.user.userPic,
                // idUser: action.payload.user.myID,
                // message: action.payload.message,
                // timeCreatMessage: action.payload.timeCreatMessage,
            }]
        },
    },
    extraReducers: () => {
    },
})


export const messageDialogsReducer = messageDialogsSlice.reducer
export const messageDialogsAction = messageDialogsSlice.actions


