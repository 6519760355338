import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import { IResponseCollection } from '../../types/responseConstantsType';
import {IConstantCollection} from "../../types/constantsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export const fetchConstInterestProblems = createAsyncThunk<
    IConstantCollection[],
    void,
    ThunkConfig<string>
    >(
        'constants/fetchConstInterestProblems',
        async (_, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;

            try {
                const response = await extra.api.get<BaseResponse<IResponseCollection>>(
                    `constants/interest-problem`);
                if (!response.data.success) {
                    return rejectWithValue(response.data.codeKey)
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                console.log('err', err)
                // @ts-ignore
                return rejectWithValue(err.data.error);
            }
        },
    );
