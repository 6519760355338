import {memo} from 'react'
import s from './FooterBox.module.css';
import {classNames} from "../../utils/helpers/classNames/classNames";
import {useAppSelector} from "../../store/hooks/redux";
import {getFooterIsShow} from "../../store/reducers/footer/getShowFooter/getFooterIsShow";
import {Footer} from "./footerComponent/Footer";

export const FooterBox = () => {
    const isShowFooter = useAppSelector(getFooterIsShow)
    if(!isShowFooter) {
        return  null
    }
    return (
        <Footer/>
    );
};
