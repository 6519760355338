import {useCallback, useMemo, useRef, useState} from "react";

export const useTouchUp = () => {
    const [touchStart, setTouchStart] = useState<null | number>(null)
    const [touchEnd, setTouchEnd] = useState<null | number>(null)
    const [isSwapUp, setIsSwap] = useState<boolean>(false)
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientY)
    }, [])

    const onTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) =>
        setTouchEnd(e.targetTouches[0].clientY), [])

    const onTouchEnd = useCallback(() => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isUpSwipe = distance > minSwipeDistance
        if (isUpSwipe) {
            setIsSwap(true)
            timerRef.current = setTimeout(() => {
                setIsSwap(false)
            }, 400);
        }
    }, [touchEnd, touchStart])

    return useMemo(() => {
        return {
            onTouchStart, onTouchMove, onTouchEnd, isSwapUp
        }
    }, [isSwapUp, onTouchEnd, onTouchMove, onTouchStart])

}
