import {Navigate, useLocation} from 'react-router-dom';
import {RoutePath} from "./routerConfig";
import {useAppSelector} from "../../store/hooks/redux";
import {getProfileGroupId} from "../../store/reducers/profile/selectors/getProfile/getProfile";
import {UserGroup} from "../../store/reducers/profile/types/profileTypes";

export function RequireAuthProfile({children}: { children: JSX.Element }) {
    const userGroup = useAppSelector(getProfileGroupId)
    const location = useLocation();
    if (userGroup  === UserGroup.USER_PREMIUM || userGroup === UserGroup.USER) {
        return children;
    }

    return <Navigate to={RoutePath.main} state={{from: location}} replace/>;

}
