import {memo, useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import {closeSnackbar, enqueueSnackbar, SnackbarKey, SnackbarProvider} from 'notistack'
import {useAppDispatch, useAppSelector} from "../../store/hooks/redux";
import {snackbarAction} from "../../store/reducers/errors/SnackbarSlice";
import {
    getSnackbarErrors,
    getSnackbarNotification, getSnackbarPrivateMessage
} from "../../store/reducers/errors/selectors/getSnackbarMain";
import s from './Snackbar.module.css'
import {INotification} from "../../store/reducers/notifications/types/notificationsTypes";


export const Snackbar = memo(() => {
    const errors = useAppSelector(getSnackbarErrors)
    const notifications = useAppSelector(getSnackbarNotification)
    const privateMessage = useAppSelector(getSnackbarPrivateMessage)

    const dispatch = useAppDispatch()

    const {
        clearError,
        clearNotification,
    } = snackbarAction

    const {t} = useTranslation(['errors', 'notification']);

    useEffect(() => {
        if (errors) {
            errors.forEach(error => {
                const key: SnackbarKey = enqueueSnackbar(t(`errors:${error.message}`), {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    SnackbarProps: {
                        onClick: () => closeSnackbar(key)
                    }
                });
                dispatch(clearError(error.id));
            });
        }
    }, [errors, dispatch, t, clearError]);

    //FRIENDS
    const messageAddFriend = useCallback((nt: INotification) => {
        if (nt.linkedUser) {
            const name = nt.linkedUser.name ? nt.linkedUser.name : nt.linkedUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:add-new-friends')}
            </p>
        }
    }, [t])

    const messageAcceptFriend = useCallback((nt: INotification) => {
        if (nt.linkedUser) {
            const name = nt.linkedUser.name ? nt.linkedUser.name : nt.linkedUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:accept-friends')}
            </p>
        }
    }, [t])

    const messageDeclineFriend = useCallback((nt: INotification) => {
        if (nt.linkedUser) {
            const name = nt.linkedUser.name ? nt.linkedUser.name : nt.linkedUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:decline-friends')}
            </p>
        }
    }, [t])


    //CHAT
    const messageNewRequestInChat = useCallback((nt: INotification) => {
        if (nt.chat && nt.chatUser) {
            const name = nt.chatUser.user.name ? nt.chatUser.user.name : nt.chatUser.user.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:add-new-request-in-chat')}
                {' '}
                <span className={s.user}>{' '}{nt.chat.name}</span>
            </p>
        }
    }, [t])

    const messageAcceptRequestInChat = useCallback((nt: INotification) => {
        if (nt.chat && nt.chatUser) {
            const name = nt.chat.createdByUser.name ? nt.chat.createdByUser.name : nt.chat.createdByUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:accept-chat')}
                {' '}
                <span className={s.user}>{' '}{nt.chat.name}</span>
            </p>
        }
    }, [t])

    const messageDeclineRequestInChat = useCallback((nt: INotification) => {
        if (nt.chat && nt.chatUser) {
            const name = nt.chat.createdByUser.name ? nt.chat.createdByUser.name : nt.chat.createdByUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:decline-chat')}
                {' '}
                <span className={s.user}>{' '}{nt.chat.name}</span>
            </p>
        }
    }, [t])


    //ROOM
    const messageNewRequestInRoom = useCallback((nt: INotification) => {
        if (nt.room && nt.roomUser) {
            const name = nt.roomUser.user.name ? nt.roomUser.user.name : nt.roomUser.user.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:add-new-request-in-room')}
                {' '}
                <span className={s.user}>{' '}{nt.room.name}</span>
            </p>
        }
    }, [t])

    const messageAcceptRequestInRoom = useCallback((nt: INotification) => {
        if (nt.room && nt.roomUser) {
            const name = nt.room.createdByUser.name ? nt.room.createdByUser.name : nt.room.createdByUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:accept-room')}
                {' '}
                <span className={s.user}>{' '}{nt.room.name}</span>
            </p>
        }
    }, [t])

    const messageDeclineRequestInRoom = useCallback((nt: INotification) => {
        if (nt.room && nt.roomUser) {
            const name = nt.room.createdByUser.name ? nt.room.createdByUser.name : nt.room.createdByUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:decline-room')}
                {' '}
                <span className={s.user}>{' '}{nt.room.name}</span>
            </p>
        }
    }, [t])

    const messageCalendarSubscribed = useCallback((nt: INotification) => {
        if (nt.schedule && nt.linkedUser) {
            const name = nt.linkedUser.name ? nt.linkedUser.name : nt.linkedUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:calendar-subscribed')}
                {' '}
                <span className={s.user}>{' '}{nt.schedule.name}</span>
            </p>
        }
    }, [t])

    const messageCalendarUnsubscribed = useCallback((nt: INotification) => {
        if (nt.schedule && nt.linkedUser) {
            const name = nt.linkedUser.name ? nt.linkedUser.name : nt.linkedUser.nickname
            return <p
                className={s.message}
            >
                {t('notification:user')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:calendar-unsubscribed')}
                {' '}
                <span className={s.user}>{' '}{nt.schedule.name}</span>
            </p>
        }
    }, [t])

    const messageCalendar5min = useCallback((nt: INotification) => {
        if (nt.schedule) {
            const name = nt.schedule.name
            const roomName = nt.schedule.room?.name
            return <p
                className={s.message}
            >
                {t('notification:calendar')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:calendar-5-min')}
                {' '}
                {
                    roomName
                        ? (<>
                            <span className={s.text}>{t('notification:calendar-room')}</span>
                            {' '}
                            <span className={s.user}>{' '}{roomName}</span>
                        </>)
                        :
                        null
                }

            </p>
        }
    }, [t])

    const messageCalendarStart = useCallback((nt: INotification) => {
        if (nt.schedule) {
            const name = nt.schedule.name
            const roomName = nt.schedule.room?.name
            return <p
                className={s.message}
            >
                {t('notification:calendar')}
                <span className={s.user}>{name}</span>
                {t('notification:calendar-start')}

                {
                    roomName
                        ? (<>
                            <span className={s.text}>{t('notification:calendar-room')}</span>

                            <span className={s.user}>{roomName}</span>
                        </>)
                        :
                        null
                }

            </p>
        }
    }, [t])

    const messageCalendar60min = useCallback((nt: INotification) => {
        if (nt.schedule) {
            const name = nt.schedule.name
            const roomName = nt.schedule.room?.name
            return <p
                className={s.message}
            >
                {t('notification:calendar')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:calendar-60-min')}
                {' '}
                {
                    roomName
                        ? (<>
                            <span className={s.text}>{t('notification:calendar-room')}</span>
                            {' '}
                            <span className={s.user}>{' '}{roomName}</span>
                        </>)
                        :
                        null
                }

            </p>
        }
    }, [t])

    const messageCalendarEnd = useCallback((nt: INotification) => {
        if (nt.schedule) {
            const name = nt.schedule.name
            const roomName = nt.schedule.room?.name
            return <p
                className={s.message}
            >
                {t('notification:calendar')}
                {' '}
                <span className={s.user}>{name}{' '}</span>
                {t('notification:calendar-end')}
                {' '}
                {
                    roomName
                        ? (<>
                            <span className={s.text}>{t('notification:calendar-room')}</span>
                            <span className={s.user}>{' '}{roomName}</span>
                        </>)
                        :
                        null
                }

            </p>
        }
    }, [t])

    useEffect(() => {
        if (notifications) {
            notifications.forEach(nt => {
                console.log('nt', nt)
                switch (nt.event) {
                //ДРУЗЬЯ
                //Пользователь хочет добавить в друзья
                case "USER_RELATIONSHIP_FRIEND_NEW":
                    if (nt.linkedUser && nt.is_self === 0) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageAddFriend(nt) ?? '',

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь принял заявку в друзья
                case "USER_RELATIONSHIP_FRIEND_ACCEPT":
                    if (nt.linkedUser && nt.is_self === 0) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageAcceptFriend(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь отклонил заявку в друзья
                case "USER_RELATIONSHIP_FRIEND_DECLINE":
                    if (nt.linkedUser && nt.is_self === 0) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageDeclineFriend(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break



                    //Чаты
                    //Пользователь хочет вступить в чат
                case "CHAT_USER_REQUEST_NEW":
                    if (nt.chat && nt.is_self === 0 && nt.chatUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageNewRequestInChat(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь одобрил заявку на вступление в чат
                case "CHAT_USER_REQUEST_APPROVED":
                    if (nt.chat && nt.is_self === 0 && nt.chatUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageAcceptRequestInChat(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь отклонил заявку на вступление в чат
                case "CHAT_USER_REQUEST_DECLINED":
                    if (nt.chat && nt.is_self === 0 && nt.chatUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageDeclineRequestInChat(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break



                    //Комнаты
                    //Пользователь хочет вступить в комнату
                case "ROOM_USER_REQUEST_NEW":
                    if (nt.room && nt.is_self === 0 && nt.roomUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageNewRequestInRoom(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь одобрил заявку на вступление в комнату
                case "ROOM_USER_REQUEST_APPROVED":
                    if (nt.room && nt.is_self === 0 && nt.roomUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageAcceptRequestInRoom(nt) ?? t('notification:accpet-room'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //Пользователь отклонил заявку на вступление в комнату
                case "ROOM_USER_REQUEST_DECLINED":
                    if (nt.room && nt.is_self === 0 && nt.roomUser) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageDeclineRequestInRoom(nt) ?? t('notification:decline-room'),

                            {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break


                    //calendar
                    //добавился новый пользователь
                case "SCHEDULE_USER_SUBSCRIBED":
                    if (nt.linkedUser && nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendarSubscribed(nt) ?? t('notification:calendar-subscribed'),

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //отписался пользователь
                case "SCHEDULE_USER_UNSUBSCRIBED":
                    if (nt.linkedUser && nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendarUnsubscribed(nt) ?? t('notification:calendar-unsubscribed'),

                            {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //5 мин до начала сессии
                case "SCHEDULE_5MIN_START":
                    if (nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendar5min(nt)
                            ?? `${t('notification:calendar')} ${t('notification:calendar-5-min')}`,

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //сессия началась
                case "SCHEDULE_START":
                    if (nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendarStart(nt)
                                ?? `${t('notification:calendar')} ${t('notification:calendar-start')}`,

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //60 мин до начала сессии
                case "SCHEDULE_60MIN_START":
                    if (nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendar60min(nt)
                            ?? `${t('notification:calendar')} ${t('notification:calendar-60-min')}`,

                            {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                    //сессия закончилась
                case "SCHEDULE_END":
                    if (nt.is_self === 0 && nt.schedule) {
                        const key: SnackbarKey = enqueueSnackbar(
                            messageCalendarEnd(nt)
                                ?? `${t('notification:calendar')} ${t('notification:calendar-end')}`,

                            {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },

                                SnackbarProps: {
                                    onClick: () => closeSnackbar(key)
                                }
                            })
                    }
                    break

                default:
                    enqueueSnackbar(t(`notification:new-notification`), {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        }
                    },)
                }
                dispatch(clearNotification(nt.id)); // предполагаем, что у вас есть поле id в объекте ошибки
            });
        }
    }, [dispatch, t, notifications, clearNotification, messageAddFriend, messageAcceptFriend, messageDeclineFriend, messageNewRequestInChat, messageNewRequestInRoom, messageAcceptRequestInChat, messageDeclineRequestInChat, messageAcceptRequestInRoom, messageDeclineRequestInRoom, messageCalendarSubscribed, messageCalendarUnsubscribed, messageCalendar5min, messageCalendar60min, messageCalendarEnd, messageCalendarStart]);

    useEffect(()=> {
        if(privateMessage !== undefined) {
            const key: SnackbarKey = enqueueSnackbar(
                <p>{t('notification:private-chat')}</p>,

                {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },

                    SnackbarProps: {
                        onClick: () => closeSnackbar(key)
                    }
                })
        }
    }, [privateMessage, t])

    return (
        <SnackbarProvider autoHideDuration={4000} className={s.Snackbar} />
    );
});
