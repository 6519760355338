import {Suspense, useCallback} from 'react'
import {Route, Routes} from 'react-router-dom'
import {AppRoutersProps, routerConfig} from "./routerConfig/routerConfig";
import {Loader} from "../components/loader/Loader";
import {RequireAuthProfile} from "./routerConfig/RequireAuthProfile";
import {RequireAuthSpecialist} from "./routerConfig/RequireAuthSpecialist";
import {ScrollToTop} from "./scrollToTop/scrollToTop";

export const PrivateRoute = () => {

    const renderWithWrapper = useCallback((route: AppRoutersProps) => {
        const element = (
            <Suspense fallback={<Loader/>}>
                {route.element}
            </Suspense>
        );

        return (
            <Route
                key={route.path}
                element={route.authOnlySpecialist ?
                    <RequireAuthSpecialist>{element}</RequireAuthSpecialist> :
                    route.authOnlyUser ?
                        <RequireAuthProfile>{element}</RequireAuthProfile> : element}
                path={route.path}
            />
        );
    }, []);

    return (
        <Suspense fallback={<Loader/>}>
            <ScrollToTop>
                <Routes>
                    {Object.values(routerConfig).map(renderWithWrapper)}
                </Routes>
            </ScrollToTop>
        </Suspense>
    )
}
