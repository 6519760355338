import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {setupStore} from "./store/store";
import {Provider} from "react-redux";
import './18n';

import './App.css';
import './common/css/btnMain.css';
import './common/css/ContainerMain.css';
import './common/css/ChatMain.css';
import './common/css/ChatRoomItemMain.css';
import './common/css/TooltipMain.css';
import './common/css/chatRoomViewMain.css';
import './common/css/MenuHeaderMain.css';
import './common/css/HeaderMain.css';
import './common/css/ProfileMain.css';
import './common/css/SpecialistProfileMain.css';
import './common/css/TestPageMain.css';
import './common/css/Main.css';
import './common/css/ModalMain.css';
import './common/css/SchedulerMain.css';
import {Loader} from "./components/loader/Loader";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = setupStore()
root.render(
    <Provider store={store}>
        <Suspense fallback={<Loader/>}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Suspense>
    </Provider>
);

reportWebVitals();
