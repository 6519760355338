import {AppDispatch} from "../../../store";
import {
    fetchConstInterestAddiction
} from "./fetchConstInterestAddiction/fetchConstInterestAddiction";
import {authAction} from "../../auth/authSlice";
import {fetchConstUserStatus} from "./fetchConstUserStatus/fetchConstUserStatus";
import {fetchConstInterestProblems} from "./fetchConstInterestProblems/fetchConstInterestProblems";

export const fetchConstants = async (dispatch: AppDispatch) => {
    // await Promise.all([
    //     dispatch(fetchConstInterestAddiction()),
    //     dispatch(fetchConstInterestProblems()),
    //     dispatch(fetchConstUserStatus())
    // ]);
    // await Promise.all([
    dispatch(fetchConstInterestAddiction()),
    dispatch(fetchConstInterestProblems()),
    dispatch(fetchConstUserStatus())
    // ]);
}
