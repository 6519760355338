import {memo, useCallback, useState} from 'react';
import s from './NavbarBurger.module.css'
import {useAppSelector} from "../../../store/hooks/redux";
import {useOutside} from "../../../hooks/useOutside";
import {LoginModal} from "../../modal/loginModal/LoginModal";
import {classNames, Mods} from "../../../utils/helpers/classNames/classNames";
import {NavbarMenuBurger} from "./NavbarMenuBurger/NavbarMenuBurger";
import {useCloseMenuOnScroll} from "../../../hooks/useCloseMenuOnScroll";
import {NavbarBurgerMainBox} from "./NavbarMenuBurger/NavbarBurgerMainBox/NavbarBurgerMainBox";
import {useMatchMedia} from "../../../hooks/useMatchMedia";
import {
    getShowNavbarBurger
} from "../../../store/reducers/navbar/getShowNavbarBurger/getShowNavbarBurger";
import {getNavbarShow} from "../../../store/reducers/navbar/getShowNavbar/getNavbarShow";
import {
    getProfileIsLoading,
    getProfileIsLoadingProfile
} from "../../../store/reducers/profile/selectors/getProfile/getProfile";
import {NavbarLoader} from "../../../ui/navbarLoader/NavbarLoader";
import {
    getAuthIsAuthentication,
    getAuthIsNavbarLoading
} from "../../../store/reducers/auth/selectors/getAuth/getAuth";
import {
    getChatsIsLoadingChat
} from "../../../store/reducers/chats/selectors/getChatsIsLoadingChat/getChatsIsLoadingChat";
import {
    getRoomsIsLoadingRoom
} from "../../../store/reducers/rooms/selectors/getRoomsIsLoadingRoom/getRoomsIsLoadingRoom";
import {
    getSpecialistsFindIsLoading
} from "../../../store/reducers/specialistFind/selectors/getSpecialistsFind/getSpecialistsFind";
import {
    getSchedulerIsLoading
} from "../../../store/reducers/scheduler/selectors/getSchedulerIsLoading/getSchedulerIsLoading";
import {
    getNotificationsIsLoading
} from "../../../store/reducers/notifications/selectors/getNotifications";

export const NavbarBurger = memo(() => {
    const [isActiveModalLogin, setIsActiveModalLogin] = useState(false)

    const {
        ref,
        isShow,
        setIsShow
    } = useOutside(false)

    const isShowNavbar = useAppSelector(getNavbarShow)
    const isShowNavbarBurger = useAppSelector(getShowNavbarBurger)
    const isAuthentication = useAppSelector(getAuthIsAuthentication)

    const isNavbarLoading = useAppSelector(getAuthIsNavbarLoading)
    const isProfileIsLoading = useAppSelector(getProfileIsLoadingProfile)
    const isChatsIsLoadingChat = useAppSelector(getChatsIsLoadingChat)
    const isRoomsIsLoadingChat = useAppSelector(getRoomsIsLoadingRoom)
    const isSpecialistsFindLoading = useAppSelector(getSpecialistsFindIsLoading)
    const isCalendarIsLoading = useAppSelector(getSchedulerIsLoading)
    const isNotificationIsLoading = useAppSelector(getNotificationsIsLoading);

    const showBurgerMenu = useCallback(() => {
        setIsShow(prev => !prev)
    }, [setIsShow])

    const closeBurgerMenu = useCallback(() => {
        setIsShow(() => false)
    }, [setIsShow])

    const showModalLoginHandler = useCallback(() => {
        setIsActiveModalLogin(() => true)
        setIsShow(() => false)
    }, [setIsShow])

    const onCloseModalLoginHandler = useCallback(() => {
        setIsActiveModalLogin(() => false)
    }, [])

    //закрытие меню при скролле
    const closeMenuProfile = useCallback(() => {
        setIsShow(false)
    }, [setIsShow])


    const {isMobile} = useMatchMedia()
    useCloseMenuOnScroll(isShow, closeMenuProfile, isMobile)
    // const {onTouchMove, onTouchStart, onTouchEnd, isSwapUp} = useTouchUp()
    //
    // useEffect(()=> {
    //     if(isSwapUp) {
    //         setIsShow(false)
    //     }
    // }, [isSwapUp, setIsShow])
    // const changeIsShowBurgerMenuWhenScroll = useCallback(() => {
    //     setIsShow(false)
    // }, [isShow])
    //
    // useEffect(() => {
    //
    //     if (isShow) {
    //         window.addEventListener('scroll', changeIsShowBurgerMenuWhenScroll);
    //     }
    //     if (!isShow) {
    //         window.removeEventListener('scroll', changeIsShowBurgerMenuWhenScroll);
    //     }
    //     return () => {
    //         window.removeEventListener('scroll', changeIsShowBurgerMenuWhenScroll);
    //     };
    // }, [isShow]);

    const mods: Mods = {
        ['header-out']: !isShowNavbar || !isShowNavbarBurger
    }

    const isLoading = isNavbarLoading
        || isProfileIsLoading
        || isChatsIsLoadingChat
        || isRoomsIsLoadingChat
        || isSpecialistsFindLoading
        || isCalendarIsLoading
        || isNotificationIsLoading

    return (
        <>
            <header className={classNames(s.headerBurger, mods, ['headerBurgerMain'])}
                ref={ref}>

                <NavbarBurgerMainBox
                    closeBurgerMenu={closeBurgerMenu}
                    showBurgerMenu={showBurgerMenu}
                    showModalLogin={showModalLoginHandler}
                    isAuthentication={isAuthentication}
                    isShow={isShow}
                />

                <NavbarMenuBurger
                    showModalLogin={showModalLoginHandler}
                    isAuthentication={isAuthentication}
                    setIsShow={setIsShow}
                    isShow={isShow}
                    onClose={closeMenuProfile}
                />
                {isLoading && <NavbarLoader/>}

            </header>

            <LoginModal
                isOpen={isActiveModalLogin}
                onClose={onCloseModalLoginHandler}
            />
        </>
    );
});
