import {memo} from 'react';
import s from './HeaderNotification.module.css'
import {ReactComponent as NotificationSVG} from '../../../utils/images/icons/notifications.svg'
import notificationStyle from '../../../common/HeaderNotification.module.css'
import {NotificationMenuHeader} from "./notificationMenuHeader/NotificationMenuHeader";
import {useOutside} from "../../../hooks/useOutside";
import {useCloseMenuOnScroll} from "../../../hooks/useCloseMenuOnScroll";
import {useAppSelector} from "../../../store/hooks/redux";
import {
    getNotificationCollection,
    getNotificationPrivateChat,
    getNotificationsStart,
    getNumberOfNotifications
} from "../../../store/reducers/notifications/selectors/getNotifications";

type HeaderNotificationProps = {
    closeMenuBurger?: () => void
}

export const HeaderNotification = memo(({closeMenuBurger}: HeaderNotificationProps) => {

    const notification = useAppSelector(getNotificationCollection)
    const numberOfNewNotifications = useAppSelector(getNumberOfNotifications)
    const notificationStart = useAppSelector(getNotificationsStart)
    const notificationPrivateChat = useAppSelector(getNotificationPrivateChat)

    const {
        isShow,
        setIsShow,
        ref
    } = useOutside(false)

    const onChangeShowNotificationHandler = () => {
        setIsShow(prev => !prev)
        if (closeMenuBurger) {
            closeMenuBurger()
        }
    }

    //закрытие меню при скролле
    const closeMenuProfile = () => {
        setIsShow(false)
    }
    useCloseMenuOnScroll(isShow, closeMenuProfile, false)

    const isNewNotification = notification
        && notification.length > 0
        || numberOfNewNotifications.newFriends > 0
        || numberOfNewNotifications.newRequestInChats > 0
        || numberOfNewNotifications.newRequestInRooms > 0
        || notificationStart
        || notificationPrivateChat

    return (
        <div
            className={notificationStyle.header__notifications}
            ref={ref}
        >
            <div className={s.header__notificationLabel}
                onClick={onChangeShowNotificationHandler}

            >
                <NotificationSVG/>
                {isNewNotification && <span className={s.notiCount}></span>}
            </div>
            <NotificationMenuHeader
                notificationData={notification}
                isShow={isShow}
                numberOfNewNotifications={numberOfNewNotifications}
                closeNotification={closeMenuProfile}
                notificationStart={notificationStart}
                notificationPrivateChat={notificationPrivateChat}
            />
        </div>
    );
});
