export interface IFooterPage {
  title: string
  path: string
}

export const footerPages: IFooterPage[] = [
    {
        title: 'О компании',
        path: '/'
    },
    {
        title: 'Специальные возможности',
        path: '/'
    },
    {
        title: 'Пользовательское соглашение',
        path: '/'
    },
    {
        title: 'Политика конфиденциальности',
        path: '/'
    },
    {
        title: 'Политика использования файлов cookie',
        path: '/'
    },
    {
        title: 'Политика защиты авторских прав',
        path: '/'
    },
    {
        title: 'Политика торговой марки',
        path: '/'
    },
    {
        title: 'Политика сообщества',
        path: '/'
    },
    {
        title: 'Справка',
        path: '/'
    },
    {
        title: 'Общие сведения',
        path: '/'
    },
    {
        title: 'Пресса',
        path: '/'
    },
    {
        title: 'Блог',
        path: '/'
    },
]
